// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'client',
    label: '',
    Object: 'value',
    width: '80'
  }, {
    prop: 'user',
    label: '',
    Object: 'value',
    width: '160'
  },{
    prop: 'order_detail',
    label: '',
    Object: 'value',
    width: '400'
  }, {
    prop: 'status',
    label: '',
    Object: 'value',
    width: '180'
  }]
}
